import { $get, $post, dealError } from "@/utils"
export const GET_CHECKINDATA = async (year, month) => {
  try {
    const { ret, msg, data } = await $get("/marketing/qiandao", { year, month });
    return Promise.resolve(data)
  } catch (error) {
    dealError(error)
  }
}

export const CHECK = async () => $post("/marketing/qiandao", { act: 'sign' })

// 签到获取礼物模式公用
const CHECKWITHGIFTCOMMON = async (_data = {}) => {
  try {
    const { ret, msg, data } = await $post("/marketing/gift", { type: "sign_gift", ..._data })
    return Promise.resolve(data)
  } catch (error) {
    dealError(error)
  }
}

// 获取签到初始化信息
export const CHECKWITHGIFTINIT = async (year, month) => CHECKWITHGIFTCOMMON({ act: "setting", year, month })

export const CHECKWITHGIFT = async () => CHECKWITHGIFTCOMMON({ act: "sign" });

// 获取领取记录
export const GET_CHECKINRECORD = async (data) => CHECKWITHGIFTCOMMON({ act: list, ...data })